import axios from 'axios';

import { authInterceptor, BASE_API_URL, AuthorizedService } from './index';

export const authorizedReq = axios.create();
authorizedReq.interceptors.request.use(...authInterceptor);

export const applyCommonActionWithUri = (uri) =>
  authorizedReq({
    method: 'GET',
    url: `${BASE_API_URL}/${uri}`,
  });

export const getUserDetails = () =>
  authorizedReq.get(BASE_API_URL + '/user/details');
const pluginService = (url) => AuthorizedService(url);
const merchantService = pluginService('/config/');
export const getMerchantConfig = (noAuthRequired) =>
  merchantService({
    method: 'GET',
    headers: {
      noAuthRequired,
    },
  });
