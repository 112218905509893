import { AuthorizedService } from '../index';

export const PluginsService = (url, isBearer) =>
  AuthorizedService('/plugins' + url, isBearer);

export const DashboardService = () => AuthorizedService('/dashboard');

const pluginsService = PluginsService('');

export const getSectionsConfig = () => pluginsService.get('/sections/', true);
