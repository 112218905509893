import { render, staticRenderFns } from "./Version.vue?vue&type=template&id=4841922c&scoped=true"
import script from "./Version.vue?vue&type=script&lang=js"
export * from "./Version.vue?vue&type=script&lang=js"
import style0 from "./Version.vue?vue&type=style&index=0&id=4841922c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4841922c",
  null
  
)

export default component.exports